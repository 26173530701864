import * as React from 'react';



const RedCircle: React.FunctionComponent = () => {

  return (
    <React.Fragment>
      <div className='red-circle-container'>
        <div className='red-circle'>
        </div>
      </div>

    </React.Fragment>
  )
}

export { RedCircle };


