import * as React from 'react';
import HDDIcon from '@patternfly/react-icons/dist/esm/icons/hdd-icon';


import { TreeView, Button, TreeViewDataItem } from '@patternfly/react-core';
import { backendURL } from '@app/TreeContainer/BackendURL';

export interface TreeViewProps {
  onSelect?: (event: React.MouseEvent, item: TreeViewDataItem, parentItem: TreeViewDataItem) => void;
  /** Callback for expanding a node with children. */
}
export const VolumeTreeViewImpl: React.FunctionComponent<TreeViewProps> = ({onSelect}) => {
  const [activeItems, setActiveItems] = React.useState<TreeViewDataItem[]>();
  const [allExpanded, setAllExpanded] = React.useState<boolean>();

  const onToggle = (_event: React.MouseEvent) => {
    setAllExpanded((prevAllExpanded) => !prevAllExpanded);
  };


  const [options, setOptions] = React.useState([{
    name: 'Loading...',
    id: 'loading',
    children: []
  }]);
  React.useEffect(() => {
    fetch(`${backendURL}/tree-volumes`)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                console.log(data);
                // options = data;
                setOptions(data);
            })

    return () => {    };
  }, []);


  return (
    <React.Fragment>
      <TreeView data={options} activeItems={activeItems} onSelect={onSelect} allExpanded={allExpanded} icon={<HDDIcon />}
      expandedIcon={<HDDIcon />}></TreeView>
    </React.Fragment>
  );
};

