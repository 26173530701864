import * as React from 'react';
import {
  Button,
  Checkbox,
  Modal,
  ModalVariant
} from '@patternfly/react-core';
import { DataContext } from '@app/DataContext';
import { useContext } from 'react';
import { backendURL } from '@app/TreeContainer/BackendURL';


export const DeleteVMModal: React.FunctionComponent = () => {
  const { refreshTree, setRefreshTree } = useContext(DataContext);
  const { notifications, setNotifications } = useContext(DataContext);
  const { viewIndex, setViewIndex } = useContext(DataContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const VM_INDEX = 2;

  const confirm = async (_event: KeyboardEvent | React.MouseEvent) => {
    // delete vm and delete data volume
    let response = await fetch(`${backendURL}/vmi/${showDeleteModal?.name}`, {
      method: "DELETE"
    });
    console.log('delete vmi: ', response);
    if (response.ok && !isChecked) {
      setIsModalOpen(false);
      setShowDeleteModal(undefined);
    }

    if (response.ok && isChecked) {
      let isOk = true;
      showDeleteModal?.dataVolumes.forEach(async (item) => {
        let response = await fetch(`${backendURL}/datavolume/${item.name}`, {
          method: "DELETE"
        });
        if (!response.ok) {
          isOk = false;
        }
      });
      if (isOk) {
        setRefreshTree(refreshTree + 1);

        setViewIndex(VM_INDEX);

        let notification = `Virtual machine '${showDeleteModal?.name}' successfully deleted.`;
        let notifications_local = [notification, ...notifications];
        setNotifications(notifications_local);

        setIsModalOpen(false);
        setShowDeleteModal(undefined);
      }
    }
  };

  const closeModal = (_event: KeyboardEvent | React.MouseEvent) => {
    setIsModalOpen(false);
    setShowDeleteModal(undefined);
  };


  const { showDeleteModal, setShowDeleteModal } = useContext(DataContext);
  React.useEffect(() => {
    if (showDeleteModal !== undefined) {
      setIsModalOpen(true);
    }
    console.log('showing delete modal')
  }, [showDeleteModal]);

  const [isChecked, setIsChecked] = React.useState<boolean>(false);

  const handleChange = (event: React.FormEvent<HTMLInputElement>, checked: boolean) => {
    setIsChecked(checked);
  };

  return (
    <React.Fragment>
      <Modal
        variant={ModalVariant.small}
        title="Delete Virtual Machine"
        isOpen={isModalOpen}
        onClose={closeModal}
        actions={[
          <Button key="confirm" variant="primary" onClick={confirm}>
            Confirm
          </Button>,
          <Button key="cancel" variant="link" onClick={closeModal}>
            Cancel
          </Button>
        ]}
      >
        <div className='delete-label'>Are you sure you want to remove virtual machine?</div>

        <Checkbox
          label="Delete DataVolume as well"
          isChecked={isChecked}
          onChange={handleChange}
          id="controlled-check-2"
          name="check2"
        />
      </Modal>
    </React.Fragment>
  );
};