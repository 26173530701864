import * as React from 'react';
import { PageSection, Title } from '@patternfly/react-core';
import { VMsDashboard } from './VMsDashboard';

const VirtualMachines: React.FunctionComponent = () => (
  <PageSection>
    <Title headingLevel="h1" size="lg">Virtual Machines Dashboard</Title>
    <VMsDashboard />
  </PageSection>
)

// const SampleElement: React.FunctionComponent = () => (
//   <>
//     <div>test element</div>
//     test
//   </>
// )


export { VirtualMachines };
