import * as React from 'react';
import { PageSection, Title } from '@patternfly/react-core';
import { VolumesDashboard } from './VolumesDashboard';

const Volumes: React.FunctionComponent = () => (
  <PageSection>
    <Title headingLevel="h1" size="lg">Volumes Dashboard</Title>
    <VolumesDashboard />
  </PageSection>
)

// const SampleElement: React.FunctionComponent = () => (
//   <>
//     <div>test element</div>
//     test
//   </>
// )


export { Volumes };
