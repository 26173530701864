import * as React from 'react';
import { Avatar } from '@patternfly/react-core';

const UsernameAvatar: React.FunctionComponent = () => {
  return (
    <div className="username-avatar">
          <Avatar src="https://ca.slack-edge.com/T06PC6HC6R1-U06NXNS5AB1-df7e600da252-512" alt="avatar" size="md" />
          <div className="name">Volodymyr Tsap</div>
    </div> 
)}


export { UsernameAvatar };
