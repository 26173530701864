import * as React from 'react';
import { PageSection, Title } from '@patternfly/react-core';
import { NodesDashboard } from './NodesDashboard';

const Nodes: React.FunctionComponent = () => (
  <PageSection>
    <Title headingLevel="h1" size="lg">Nodes Dashboard</Title>
    <NodesDashboard />
  </PageSection>
)

// const SampleElement: React.FunctionComponent = () => (
//   <>
//     <div>test element</div>
//     test
//   </>
// )


export { Nodes };
