import * as React from 'react';
import { PageSection, Title } from '@patternfly/react-core';
import { PodsDashboard } from './PodsDashboard';

const Dashboard: React.FunctionComponent = () => (
  <PageSection>
    <Title headingLevel="h1" size="lg">Pods Dashboard</Title>
    <PodsDashboard />
  </PageSection>
)

// const SampleElement: React.FunctionComponent = () => (
//   <>
//     <div>test element</div>
//     test
//   </>
// )


export { Dashboard };
