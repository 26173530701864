import { backendURL } from '@app/TreeContainer/BackendURL';
import * as React from 'react';
declare const moment: any;
import { Table, Caption, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table';

export const SystemPodView: React.FunctionComponent = () => {
  let defaultState = { items: [] };
  let [pods, setPods] = React.useState(defaultState);

  React.useEffect(() => {

    let url = `${backendURL}/pods`;
    let response = fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log('pods', data);
        setPods(data);
      })
  }, [])

  const columnNames = {
    name: 'Pod',
    labels: 'Labels',
    phase: 'Phase',
    hostIP: 'Host IP',
    podIP: 'Pod IP',
    startTime: 'Start time'
  };

  const parseLabelsToArray = (labels): string[] => {
    let labelStrings: string[] = [];
    for (const key in labels) {
      const value = labels[key];
      let str = key + ":" + value;
      labelStrings.push(str);
    }
    return labelStrings;
  }

  return (
    <React.Fragment>
      <Table
        variant="compact"
        aria-label="Simple table">
        <Thead>
          <Tr>
            <Th>{columnNames.name}</Th>
            <Th>{columnNames.labels}</Th>
            <Th>{columnNames.phase}</Th>
            <Th>{columnNames.hostIP}</Th>
            <Th>{columnNames.podIP}</Th>
            <Th>{columnNames.startTime}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {pods.items.map((item: any, rowIndex) => {
            return <>
              <Tr key={item.name}>
                <Td>{item.metadata.name}</Td>
                <Td>{parseLabelsToArray(item.metadata.labels).map((label: any, index) => {
                  return <div className='chip-system'>{label}</div>
                })}</Td>
                <Td>{item.status.phase}</Td>
                <Td>{item.status.hostIP}</Td>
                <Td>{item.status.podIP}</Td>
                <Td>{moment(item.status.startTime).fromNow()}</Td>
              </Tr>
            </>
          })}
        </Tbody>
      </Table>

    </React.Fragment>
  );
};