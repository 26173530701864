import * as React from 'react';
import { Button } from '@patternfly/react-core';
import AngleDownIcon from '@patternfly/react-icons/dist/esm/icons/angle-down-icon';

const ProjectSelector: React.FunctionComponent = () => {

    return (
        <div className="project-selector">
            <div className="name">Demo</div>

            <Button variant="plain" aria-label="Action">
                <AngleDownIcon />
            </Button>
        </div>
    )
}


export { ProjectSelector };
