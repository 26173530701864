import * as React from 'react';
import OutlinedHddIcon from '@patternfly/react-icons/dist/esm/icons/outlined-hdd-icon';


import { TreeView, Button, TreeViewDataItem } from '@patternfly/react-core';
import { backendURL } from '@app/TreeContainer/BackendURL';
import { useContext } from 'react';
import { DataContext } from '@app/DataContext';

export interface TreeViewProps {
  onSelect: (event: React.MouseEvent, item: TreeViewDataItem) => void;
  /** Callback for expanding a node with children. */
}
export const VMTreeViewImpl: React.FunctionComponent<TreeViewProps> = ({ onSelect }) => {
  const [activeItems, setActiveItems] = React.useState<TreeViewDataItem[]>();
  const [allExpanded, setAllExpanded] = React.useState<boolean>(true);
  const { refreshTree, setRefreshTree } = useContext(DataContext);


  const onToggle = (_event: React.MouseEvent) => {
    setAllExpanded((prevAllExpanded) => !prevAllExpanded);
  };

  const [selected, setSelected] = React.useState<TreeViewDataItem>();
  const onSelectInner = (_event: React.MouseEvent, treeViewItem: TreeViewDataItem) => {
    console.log('onSelect1');
    if (treeViewItem) {
      setSelected(treeViewItem)
      setActiveItems([treeViewItem])
    }
    onSelect(_event, treeViewItem)
  };



  const [options, setOptions] = React.useState([{
    name: 'Loading...',
    id: 'loading',
    children: []
  }]);
  React.useEffect(() => {
    fetch(`${backendURL}/tree-namespace-data`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        // options = data;
        setOptions(data);
      })

    return () => { };
  }, [refreshTree]);


  return (
    <React.Fragment>
      <TreeView hasSelectableNodes data={options} activeItems={activeItems} onSelect={onSelectInner} allExpanded={allExpanded} icon={<OutlinedHddIcon />}
        expandedIcon={<OutlinedHddIcon />}></TreeView>
    </React.Fragment>
  );
};

