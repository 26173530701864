import { Checkbox, Label } from '@patternfly/react-core';
import { CatalogIcon, CloudTenantIcon, DesktopIcon, FolderIcon, KeyIcon, PortIcon, ServicesIcon, StarIcon, TagIcon } from '@patternfly/react-icons';
import { ExpandableRowContent, Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import * as React from 'react';



const GreenCircle: React.FunctionComponent = () => {

  return (
    <React.Fragment>
      <div className='green-circle-container'>
        <div className='green-circle'>
        </div>
      </div>

    </React.Fragment>
  )
}

export { GreenCircle };


