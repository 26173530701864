import React, { createContext, useState, ReactNode } from 'react';
import { Pod } from './TreeContainer/VMParser';

interface DataContextType {
  notifications: string[];
  setNotifications: (data: string[]) => void;
  showDeleteModal: Pod | undefined;
  setShowDeleteModal: (pod: Pod | undefined) => void;
  refreshTree: number;
  setRefreshTree: (num: number) => void;
  viewIndex: number;
  setViewIndex: (num: number) => void;
}

const defaultData: DataContextType = {
  notifications: [''],
  setNotifications: function (data: string[]): void {
    throw new Error('Function not implemented.');
  },
  showDeleteModal: undefined,
  setShowDeleteModal: function (pod: Pod | undefined): void {
    throw new Error('Function not implemented.');
  },
  refreshTree: 0,
  setRefreshTree: function (num: number): void {
    throw new Error('Function not implemented.');
  },
  viewIndex: 0,
  setViewIndex: function (num: number): void {
    throw new Error('Function not implemented.');
  }
};

export const DataContext = createContext<DataContextType>(defaultData);

interface DataProviderProps {
  children: ReactNode;
}

export const DataProvider: React.FunctionComponent<DataProviderProps> = ({ children }) => {
  const [notifications, setNotifications] = useState<string[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<Pod | undefined>(undefined);
  const [refreshTree, setRefreshTree] = useState<number>(0);
  const [viewIndex, setViewIndex] = React.useState<number>(3); //namespace by default

  return (
    <DataContext.Provider value={{
      notifications,
      setNotifications,
      showDeleteModal,
      setShowDeleteModal,
      refreshTree,
      setRefreshTree,
      viewIndex,
      setViewIndex,
    }}>
      {children}
    </DataContext.Provider>
  )
};