import * as React from 'react';



const GreyCircle: React.FunctionComponent = () => {

  return (
    <React.Fragment>
      <div className='grey-circle-container'>
        <div className='grey-circle'>
        </div>
      </div>

    </React.Fragment>
  )
}

export { GreyCircle };


