import { backendURL } from '@app/TreeContainer/BackendURL';
import * as React from 'react';
declare const moment: any;
import { Table, Caption, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table';

export const SystemDeploymentView: React.FunctionComponent = () => {
  let defaultState = { items: [] };
  let [deployments, setDeployments] = React.useState(defaultState);

  React.useEffect(() => {

    let url = `${backendURL}/deployments`;
    let response = fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log('deployments', data);
        setDeployments(data);
      })
  }, [])

  const columnNames = {
    name: 'Deployment',
    labels: 'Labels',
    replicas: 'Replicas',
    updatedReplicas: 'Updated Replicas',
    readyReplicas: 'Ready Replicas',
    availableReplicas: 'Available Replicas'
  };

  const parseLabelsToArray = (labels): string[] => {
    let labelStrings: string[] = [];
    for (const key in labels) {
      const value = labels[key];
      let str = key + ":" + value;
      labelStrings.push(str);
    }
    return labelStrings;
  }

  return (
    <React.Fragment>
      <Table
        variant="compact"
        aria-label="Simple table">
        <Thead>
          <Tr>
            <Th>{columnNames.name}</Th>
            <Th>{columnNames.labels}</Th>
            <Th>{columnNames.replicas}</Th>
            <Th>{columnNames.updatedReplicas}</Th>
            <Th>{columnNames.readyReplicas}</Th>
            <Th>{columnNames.availableReplicas}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {deployments.items.map((item: any, rowIndex) => {
            return <>
              <Tr key={item.name}>
                <Td>{item.metadata.name}</Td>
                <Td>{parseLabelsToArray(item.metadata.labels).map((label: any, index) => {
                  return <div className='chip-system'>{label}</div>
                })}</Td>
                <Td>{item.status.replicas}</Td>
                <Td>{item.status.updatedReplicas}</Td>
                <Td>{item.status.readyReplicas}</Td>
                <Td>{item.status.availableReplicas}</Td>
                
              </Tr>
            </>
          })}
        </Tbody>
      </Table>

    </React.Fragment>
  );
};