import { Button, Checkbox, Divider, Dropdown, DropdownItem, DropdownList, FormSelect, FormSelectOption, Icon, Label, MenuToggle, MenuToggleElement, TextInput } from '@patternfly/react-core';
import { CodeIcon, DesktopIcon, RegionsIcon } from '@patternfly/react-icons';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';

export interface ChangeOSProps {
  osList: any[],
  os: string,
  setOS: Dispatch<SetStateAction<string>>,
  osImageUrl: string,
  setOsImageUrl: Dispatch<SetStateAction<string>>,
}
const ChangeOS: React.FunctionComponent<ChangeOSProps> = ({ osList, os, setOS, osImageUrl, setOsImageUrl }) => {


  //change os dropdown
  const [isOpen2, setIsOpen2] = React.useState(false);

  const onToggleClick2 = () => {
    setIsOpen2(!isOpen2);
  };

  const onSelect2 = (_event: React.MouseEvent<Element, MouseEvent> | undefined, value: string | number | undefined) => {
    // eslint-disable-next-line no-console
    console.log('selected', value);
    setIsOpen2(false);
  };

  // custom http source 
  const [value2, setValue2] = React.useState('');


  //select pvc dropdown
  const [isOpen3, setIsOpen3] = React.useState(false);

  const onToggleClick3 = () => {
    setIsOpen3(!isOpen3);
  };

  const onSelect3 = (_event: React.MouseEvent<Element, MouseEvent> | undefined, value: string | number | undefined) => {
    // eslint-disable-next-line no-console
    console.log('selected', value);
    setIsOpen3(false);
  };

  const [option, setOption] = React.useState('please choose');

  const handleOptionChange = (_event: React.FormEvent<HTMLSelectElement>, value: string) => {
    setOption(value);
  };

  const handleOSChange = (_event: React.FormEvent<HTMLSelectElement>, value: string) => {
    setOS(value);
    console.log('handleOSChange', value);
  };

  const options = [
    { value: 'none', label: 'None'},
  ];

  return (
    <React.Fragment>
      <div className='change-os-container'>
        <div>
          <div>
            <DesktopIcon className='margin-change-os-icon'></DesktopIcon>
            <span className='bold-text'>Operation System</span> <span className='required-selection-os'>*</span><br />
            <FormSelect
              value={os}
              onChange={handleOSChange}
              id="horizontal-form-title"
              name="horizontal-form-title"
              aria-label="Select VM image"
            >
              {osList.map((option, index) => (
                <FormSelectOption key={index} value={option.CLOUD_USER} label={option.OS_NAME} />
              ))}
            </FormSelect>
          </div>
        </div>
        <div className='advanced-options-container'>
          <div className='advanced-options'>
            <div className='bold-text'>Advanced Options</div>
            <div>
              <CodeIcon></CodeIcon> Image From Remote Source<br />
              <TextInput
                className='custom-http-source'
                placeholder="Custom HTTP source"
                value={osImageUrl}
                type="text"
                onChange={(_event, value2) => setOsImageUrl(value2)}
                aria-label="text input example"
              />
            </div>
          </div>
          <div className='pvc-options'>
            <div className='empty-pvc-title-line'></div>
            <div>
              <RegionsIcon></RegionsIcon> Image From PVC<br />
              <FormSelect
                value={option}
                onChange={handleOptionChange}
                id="horizontal-form-title"
                name="horizontal-form-title"
                className='change-os-input-pvc'
                aria-label="Select PVC"
              >
                {options.map((option, index) => (
                  <FormSelectOption key={index} value={option.value} label={option.label} />
                ))}
              </FormSelect>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export { ChangeOS };


