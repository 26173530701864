import { DataContext } from '@app/DataContext';
import { CloudTenantIcon, DesktopIcon, FolderIcon } from '@patternfly/react-icons';
import * as React from 'react';
import { useContext } from 'react';

const ProjectView: React.FunctionComponent = () => {
  const { viewIndex, setViewIndex } = useContext(DataContext);
  const { refreshTree, setRefreshTree } = useContext(DataContext);

  const NAMESPACE_INDEX: number = 3;


  React.useEffect(() => {
    console.log('default constructor');
    if (viewIndex === NAMESPACE_INDEX) {
      setRefreshTree(refreshTree + 1);
    }
  }, [viewIndex])

  return (
    <div className='main-container-project'>
      <div className='main-options'>
        <div className='single-option'>
          <div className='single-option-header'><CloudTenantIcon className='icon-margin'></CloudTenantIcon>Download kuber config</div>
          <div>Download and install your Cloud API token (kuberconfig)
            to get programmatic access to create and manage resources via Kubernetes API</div>
          <p className='empty-line'></p>
          <div className='primary-action'>Download Now</div>
        </div>
        <div className='single-option'>
          <div className='single-option-header'><DesktopIcon className='icon-margin'></DesktopIcon>Deploy Virtual Server</div>
          <div>Deploy high-perfomance GPU and CPU based Linux and
            Windows Virtual Servers using the CoreWave Cloud Virtual Server UI or via kuberctl.</div>
          <p className='empty-line'></p>
          <div className='primary-action'>Deploy Now</div>
        </div>
        <div className='single-option'>
          <div className='single-option-header'><FolderIcon className='icon-margin'></FolderIcon>Deploy Appications</div>
          <div>Explore suite of ready-to-run, cloud-native applications.
            Manage applications from simple file management tools to custom Traefik ingresses, all
            from an easy to use Web interface.</div>
          <div className='primary-action'>Take me there</div>
        </div>
      </div>
      <div className='account-overview'>
        <div className='acoount-overview-header'>Account overview</div>
        <div className='pricing-items'>
          <div className='item-pricing'>
            <div className='item-pricing-header'>GPU Instance</div>
            <div className='pricing-title'>Running pods</div>
            <div className='pricing-value'>1</div>
            <div className='pricing-title'>CPUs</div>
            <div className='pricing-value'>9</div>
            <div className='pricing-title'>RAM</div>
            <div className='pricing-value'>65 Gi</div>
            <div className='pricing-title'>GPUs</div>
            <div className='pricing-value'>1</div>
            <div className='pricing-title'>Cost per hour</div>
            <div className='pricing-value'>$1.30</div>
          </div>
          <div className='item-pricing'>
            <div className='item-pricing-header'>CPU Instance</div>
            <div className='pricing-title'>Running pods</div>
            <div className='pricing-value'>1</div>
            <div className='pricing-title'>CPUs</div>
            <div className='pricing-value'>9</div>
            <div className='pricing-title'>RAM</div>
            <div className='pricing-value'>65 Gi</div>
            <div className='empty-line'></div>
            <div className='empty-line'></div>
            <div className='pricing-title'>Cost per hour</div>
            <div className='pricing-value'>$1.30</div>
          </div>
          <div className='item-pricing'>
            <div className='item-pricing-header'>Storage and IPs</div>
            <div className='pricing-title'>NVMe tier</div>
            <div className='pricing-value'>230 Gi</div>
            <div className='pricing-title'>HDD tier</div>
            <div className='pricing-value'>230 Gi</div>
            <div className='pricing-title'>IP addresses</div>
            <div className='pricing-value'>2</div>
            <div className='empty-line'></div>
            <div className='empty-line'></div>
            <div className='pricing-title'>Cost per hour</div>
            <div className='pricing-value'>$0.01</div>
          </div>
          <div className='item-pricing'>
            <div className='item-pricing-header'>Balance</div>
            <div className='pricing-title'>Last day spend</div>
            <div className='pricing-value'>$79.22</div>
            <div className='pricing-title'>Your biling period</div>
            <div className='pricing-value'>$10,000.38</div>
            <div className='empty-line'></div>
            <div className='empty-line'></div>
            <div className='empty-line'></div>
            <div className='empty-line'></div>
            <div className='pricing-title'>Total cost per hour</div>
            <div className='pricing-value'>$2.61</div>
          </div>
        </div>
        <div className='corewave-app-instances primary-action'>See Kube-DC Instances</div>
      </div>
    </div>
  )
}

export { ProjectView };
