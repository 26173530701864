import { backendURL } from '@app/TreeContainer/BackendURL';
import * as React from 'react';
declare const moment: any;
import { Table, Caption, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table';

export const SystemServiceView: React.FunctionComponent = () => {
  let defaultState = { items: [] };
  let [services, setServices] = React.useState(defaultState);

  React.useEffect(() => {

    let url = `${backendURL}/services`;
    let response = fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log('services', data);
        setServices(data);
      })
  }, [])

  const columnNames = {
    name: 'Service',
    labels: 'Labels',
    ports: 'Ports',
    ingress: 'Ingress',
    creationTimestamp: 'Creation Timestamp'
  };

  const parseLabelsToArray = (labels): string[] => {
    let labelStrings: string[] = [];
    for (const key in labels) {
      const value = labels[key];
      let str = key + ":" + value;
      labelStrings.push(str);
    }
    return labelStrings;
  }

  return (
    <React.Fragment>
      <Table
        variant="compact"
        aria-label="Simple table">
        <Thead>
          <Tr>
            <Th>{columnNames.name}</Th>
            <Th>{columnNames.labels}</Th>
            <Th>{columnNames.ports}</Th>
            <Th>{columnNames.ingress}</Th>
            <Th>{columnNames.creationTimestamp}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {services.items.map((item: any, rowIndex) => {
            return <>
              <Tr key={item.name}>
                <Td>{item.metadata.name}</Td>
                <Td>{parseLabelsToArray(item.metadata.labels).map((label: any, index) => {
                  return <div className='chip-system'>{label}</div>
                })}</Td>
                <Td>{item.spec.ports.map((label: any, index) => {
                  return <>
                    <div className='chip-system'>Target Port: {label.targetPort} <br />
                      Node Port: {label.nodePort} <br />
                      Port: {label.port} <br />
                      Protocol: {label.protocol} <br />
                      Name: {label.name}
                    </div>
                  </>
                })}</Td>
                <Td>{item.status.loadBalancer.ingress && item.status.loadBalancer.ingress.map((label: any, index) => {
                  return <div className='chip-system'>{label.ip}</div>
                })}</Td>
                <Td>{moment(item.metadata.creationTimestamp).fromNow()}</Td>
              </Tr>
            </>
          })}
        </Tbody>
      </Table>

    </React.Fragment>
  );
};