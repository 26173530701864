import * as React from 'react';
import { PageSection, Title } from '@patternfly/react-core';
import { NamespacesDashboard } from './NamespacesDashboard';

const Namespaces: React.FunctionComponent = () => (
  <PageSection>
    <Title headingLevel="h1" size="lg">Namespaces Dashboard</Title>
    <NamespacesDashboard />
  </PageSection>
)

// const SampleElement: React.FunctionComponent = () => (
//   <>
//     <div>test element</div>
//     test
//   </>
// )


export { Namespaces };
