import * as React from 'react';
import { Button, PageSection, Title, TreeViewDataItem } from '@patternfly/react-core';
import { VMTreeViewImpl } from '@app/TreeViewImpl/VMTreeViewImpl';
import { AddNewVMModal } from '@app/TreeContainer/VirtualMachineInstance/AddNewVMModal';
import { PodTreeViewImpl } from '@app/TreeViewImpl/PodTreeViewImpl';
import { NodeTreeViewImpl } from '@app/TreeViewImpl/NodeTreeViewImpl';
import { VolumeTreeViewImpl } from '@app/TreeViewImpl/VolumeTreeViewImpl';
import { BottomPanel } from '@app/TreeContainer/VirtualMachineInstance/Tabs/Summary/BottomPanel';
import { ActionListKebab } from '@app/TreeContainer/VirtualMachineInstance/Tabs/Summary/ActionListKebab';
import { InnerTabs } from '@app/TreeContainer/VirtualMachineInstance/InnerTabs';
import { Sidebar } from '@app/TreeViewImpl/Sidebar';
import { Tabs, Tab, TabTitleText, Checkbox, Tooltip } from '@patternfly/react-core';
import ClusterIcon from '@patternfly/react-icons/dist/esm/icons/cluster-icon';
import { Modal, ModalVariant, Wizard, WizardStep, WizardHeader } from '@patternfly/react-core';
import {
  Form,
  FormGroup,
  TextInput,
  Popover,
  ActionGroup,
  Radio,
  HelperText,
  HelperTextItem,
  FormHelperText,
  FormSelect,
  FormSelectOption,
} from '@patternfly/react-core';
import VirtualMachineIcon from '@patternfly/react-icons/dist/esm/icons/virtual-machine-icon';
import { StopIcon, PauseIcon, PlayIcon, PenIcon, PlusIcon, HelpIcon } from '@patternfly/react-icons';
import styles from '@patternfly/react-styles/css/components/Form/form';
import { Dispatch, SetStateAction, useContext } from 'react';
import { Pod } from '../VMParser';
import { DataContext } from '@app/DataContext';
import { backendURL } from '../BackendURL';


export interface VirtualMachineInstanceProps {
  activeItem: TreeViewDataItem | undefined,
  item: Pod | undefined,
  imageUrl: string | undefined,
  openedShells: any,
  setOpenedShells: Dispatch<SetStateAction<any>>,
  iframeUrl: string,
  setIframeUrl: Dispatch<SetStateAction<string>>,
  currentProgress: number,
  setCurrentProgress: Dispatch<SetStateAction<number>>,
  isLoadingSsh: boolean,
  setIsLoadingSsh: Dispatch<SetStateAction<boolean>>,
}
const VirtualMachineInstance: React.FunctionComponent<VirtualMachineInstanceProps> =
  (
    {
      activeItem,
      item,
      imageUrl,
      openedShells,
      setOpenedShells,
      iframeUrl,
      setIframeUrl,
      currentProgress,
      setCurrentProgress,
      isLoadingSsh,
      setIsLoadingSsh,
    }
  ) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const { refreshTree, setRefreshTree } = useContext(DataContext);
    const { notifications, setNotifications } = useContext(DataContext);


    const showName = (name) => {
      return name.replace(" ", "").replace("⏵", "").replace("⏹", "").replace("⚠", "").replace("⏸", "");
    }

    React.useEffect(() => {
      console.log('constructor?');
      setRefreshTree(refreshTree + 1);
    }, [activeItem]);

    const stopVM = async () => {
      console.log('stopVM');
      let url = backendURL + "/stop-vmi/" + item?.name;
      const resp = await fetch(url, {
        "method": "POST",
        "mode": "cors",
      })
      const ok = await resp.ok;
      console.log('stopVM', ok);

      if (ok) {
        setRefreshTree(refreshTree + 1);

        setTimeout(() => {
          setRefreshTree(refreshTree + 2);
        }, 10000);

        let notification = `Virtual machine '${item?.name}' successfully stopped.`;
        let notifications_local = [notification, ...notifications];
        setNotifications(notifications_local);
      }

    }

    const startVM = async () => {
      console.log('startVM');
      let url = backendURL + "/start-vmi/" + showName(activeItem?.name);
      const resp = await fetch(url, {
        "method": "POST",
        "mode": "cors",
      })
      const ok = await resp.ok;
      console.log('startVM', ok);

      if (ok) {
        setRefreshTree(refreshTree + 1);

        setTimeout(() => {
          setRefreshTree(refreshTree + 2);
        }, 10000);

        let notification = `Virtual machine '${showName(activeItem?.name)}' successfully started. It may take a while once it finishes loading.`;
        let notifications_local = [notification, ...notifications];
        setNotifications(notifications_local);
      }

    }


    const pauseVM = async () => {
      console.log('pauseVM');
      let url = backendURL + "/pause-vmi/" + item?.name;
      const resp = await fetch(url, {
        "method": "POST",
        "mode": "cors",
      })
      const ok = await resp.ok;
      console.log('pauseVM', ok);

      if (ok) {
        setRefreshTree(refreshTree + 1);

        let notification = `Virtual machine '${item?.name}' successfully paused.`;
        let notifications_local = [notification, ...notifications];
        setNotifications(notifications_local);
      }

    }


    const unpauseVM = async () => {
      console.log('unpauseVM');
      let url = backendURL + "/unpause-vmi/" + item?.name;
      const resp = await fetch(url, {
        "method": "POST",
        "mode": "cors",
      })
      const ok = await resp.ok;
      console.log('unpauseVM', ok);

      if (ok) {
        setRefreshTree(refreshTree + 1);

        let notification = `Virtual machine '${item?.name}' successfully resumed.`;
        let notifications_local = [notification, ...notifications];
        setNotifications(notifications_local);
      }

    }

    return (
      <React.Fragment>

        <div className='fullpage-content-top-section'>
          <div className='top-section-content'>
            <div className="large-icon"><ClusterIcon /></div>
            <span className="header">{(activeItem && showName(activeItem.name)) || 'Not Selected'}</span>
            {item?.printableStatus === 'Stopped' &&
              <Button variant="plain" aria-label="Start">
                <PlayIcon onClick={startVM} />
              </Button>
            }

            {item?.printableStatus === 'Running' &&
              <React.Fragment>
                <Button onClick={pauseVM} variant="plain" aria-label="Pause">
                  <PauseIcon />
                </Button>
                <Button onClick={stopVM} variant="plain" aria-label="Stop">
                  <StopIcon />
                </Button>
              </React.Fragment>
            }

            {item?.printableStatus === 'Paused' &&
              <Button onClick={unpauseVM} variant="plain" aria-label="Unpause">
                <PlayIcon />
              </Button>
            }

            <Button variant="plain" aria-label="Action">
              <PenIcon />
            </Button>
            <Button onClick={() => setIsModalOpen(true)} variant="plain" aria-label="Action">
              <PlusIcon />
            </Button>

            <ActionListKebab pod={item}></ActionListKebab>
          </div>
          <div className="inner-tabs">
            <InnerTabs
              item={item}
              imageUrl={imageUrl}
              openedShells={openedShells}
              setOpenedShells={setOpenedShells}
              iframeUrl={iframeUrl}
              setIframeUrl={setIframeUrl}
              currentProgress={currentProgress}
              setCurrentProgress={setCurrentProgress}
              isLoadingSsh={isLoadingSsh}
              setIsLoadingSsh={setIsLoadingSsh}
              activeItem={activeItem}></InnerTabs>
          </div>

        </div>
        <div className='fullpage-content-bottom-section ' >
          <div className="fullpage-content-nested-bottom-section">
            <BottomPanel item={item}
              openedShells={openedShells}
              setOpenedShells={setOpenedShells}
              iframeUrl={iframeUrl}
              currentProgress={currentProgress}
              setCurrentProgress={setCurrentProgress}
              isLoadingSsh={isLoadingSsh}
              setIsLoadingSsh={setIsLoadingSsh}
              setIframeUrl={setIframeUrl}></BottomPanel>
          </div>
        </div>
        <AddNewVMModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        >
        </AddNewVMModal>



      </React.Fragment>

    )
  }

export { VirtualMachineInstance };
