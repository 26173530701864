import * as React from 'react';
import '@patternfly/react-core/dist/styles/base.css';
import 'highlight.js/styles/default.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppLayout } from '@app/AppLayout/AppLayout';
import { AppRoutes } from '@app/routes';
import '@app/TreeContainer/VirtualMachinesView.css';
import '@app/circles.css';
import '@app/app.css';
import { DataProvider } from './DataContext';
import { DeleteVMModal } from './TreeContainer/VirtualMachineInstance/Tabs/DeleteVMModal';

const App: React.FunctionComponent = () => {
  return (
    <DataProvider>
      <Router>
        <AppLayout>
          <AppRoutes />
        </AppLayout>
      </Router>
      <DeleteVMModal></DeleteVMModal>
    </DataProvider>
  )
};

export default App;
